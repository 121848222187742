const { React_APP_API_URL } = process.env

// ** Auth Endpoints
export default {
  loginEndpoint: `${React_APP_API_URL}/api/auth/login`,
  registerEndpoint: `${React_APP_API_URL}/api/auth/register`,
  registerSupplierEndpoint: `${React_APP_API_URL}/api/auth/register_supplier`,
  registerClientEndpoint: `${React_APP_API_URL}/api/auth/register_client`,
  refreshEndpoint: `${React_APP_API_URL}/api/auth/refresh-token`,
  logoutEndpoint: `${React_APP_API_URL}/api/auth/logout`,
  rolelist: `${React_APP_API_URL}/api/roles`,
  orders: `${React_APP_API_URL}/api/orders`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
